var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"right":_vm.x === 'right',"timeout":_vm.timeout,"top":_vm.y === 'top'},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarMessage))]),(_vm.showLoader)?_c('div',{staticClass:"loader"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white"}})],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"xs":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
                  'items-per-page-options': [10, 20, 30]
                },"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.items.indexOf(item) + 1))])]}},{key:"item.statusId",fn:function(ref){
                var item = ref.item;
return [(item.statusId == 'INV_PROMISED')?_c('span',[_vm._v("Purchased")]):_vm._e(),(item.statusId == 'INV_AVAILABLE')?_c('span',[_vm._v("Available")]):_vm._e(),(item.statusId == 'INV_DELIVERED')?_c('span',[_vm._v("Delivered")]):_vm._e(),(item.statusId == 'INV_SUSPENDED')?_c('span',[_vm._v("Suspended")]):_vm._e(),(item.statusId == 'INV_DEACTIVATED')?_c('span',[_vm._v("Deactivated")]):_vm._e(),(item.statusId == 'INV_TERMINATED')?_c('span',[_vm._v("Terminated")]):_vm._e(),(item.statusId == 'INV_EXPIRED')?_c('span',[_vm._v("Expired")]):_vm._e(),(item.statusId ==  null)?_c('span',[_vm._v(" Pending Allocation")]):_vm._e(),(item.statusId ==  'INV_DUMMY')?_c('span',[_vm._v("Dummy")]):_vm._e(),(item.statusId ==  'INV_ALLO_CANCELLED')?_c('span',[_vm._v("Allocation Cancelled")]):_vm._e()]}},{key:"item.edit",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","color":"indigo"},on:{"click":function($event){return _vm.getLiveCalls(item)}}},[_vm._v(" LIVE CALL ")])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","color":"indigo"},on:{"click":function($event){return _vm.getLiveCalls(item)}}},[_vm._v(" LIVE CALL ")])]}},{key:"item.viewAction",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.viewOrder(item)}}},[_vm._v("visibility")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }